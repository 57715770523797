@font-face {
  font-family: "SF UI Display Ultralight";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Ultralight"),
    url("./assets/fonts/sf-ui-display-ultralight-58646b19bf205.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Thin";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Thin"),
    url("./assets/fonts/sf-ui-display-thin-58646e9b26e8b.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Light";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Light"),
    url("./assets/fonts/sf-ui-display-light-58646b33e0551.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Medium";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Medium"),
    url("./assets/fonts/sf-ui-display-medium-58646be638f96.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Semibold"),
    url("./assets/fonts/sf-ui-display-semibold-58646eddcae92.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Bold";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Bold"),
    url("./assets/fonts/sf-ui-display-bold-58646a511e3d9.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Heavy"),
    url("./assets/fonts/sf-ui-display-heavy-586470160b9e5.woff") format("woff");
}

@font-face {
  font-family: "SF UI Display Black";
  font-style: normal;
  font-weight: normal;
  src: local("SF UI Display Black"),
    url("./assets/fonts/sf-ui-display-black-58646a6b80d5a.woff") format("woff");
}

html,
body,
#root {
  height: 100%;
  background-color: #292929;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.w3ajs-close-btn {
  display: none !important;
}
.CollectJSFade {
  background-color: #fff;
  opacity: 0.85 !important;
  z-index: 10000;
}
#CollectJSIframe {
  z-index: 100000;
}
.toastContainer {
  z-index: 1000011111111111111321123;
}

@media screen and (max-height: 1000px) {
  .w3a-modal__inner {
    min-height: 75% !important;
  }
}

@media screen and (max-width: 990px) {
  .w3a-modal {
    width: 100%;
    justify-content: center !important;
    max-height: 78vh;
  }
  .w3a-modal .w3a-modal__inner {
    margin-right: 0 !important;
  }
  .grecaptcha-badge {
    right: -157px !important;
    transform: scale(0.5);
  }
}
.grecaptcha-badge {
  bottom: 53px !important;
  z-index: 999923;
}

.w3a-parent-container #w3a-modal {
  height: 95%;
  min-height: 95%;
}

.w3a-parent-container #w3a-modal .w3a-header {
  display: block !important;
}

.w3a-header__logo {
  float: left;
  margin-top: 5px;
  padding-right: 10px;
}
